import {Icon} from '@blueprintjs/core';
import {TextHighlight} from '@eptica/vecko-js-commons/lib/components';
import classnames from 'classnames';
import _ from 'lodash';
import React, {CSSProperties} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {services} from '../application/service/services';
import {BlankSeparator} from '../component/BlankSeparator';
import {falseSupplier} from '../utils/functionUtils';
import {feedbackHighlight} from './utils/feedbackHighlight';
import {keepHighlightedSentences} from './utils/sentenceUtils';
import {FeedbackDTO, Offset} from "../application/model";

const join = (array: Array<JSX.Element>, separator: JSX.Element, addTrailingSeparator: boolean = false): Array<JSX.Element> => {
    const result = [];
    const len = array.length;

    const item = (it, i) => {
        return React.cloneElement(it, {key: `item_${i}`});
    };

    const sep = (i) => {
        return React.cloneElement(separator, {key: `sep_${i}`});
    };

    if (len > 1 && addTrailingSeparator) {
        result.push(sep(0));
    }

    for (let i = 0; i < len; i++) {
        result.push(item(array[i], i));
        if (i < len - 1) {
            result.push(sep(i + 1));
        }
    }
    return result;
};

interface InnerFeedbackComponentProps extends WithTranslation {
    feedback: FeedbackDTO,
    shouldHighlight?: (offset: Offset, selection?) => boolean,
    onClick?: () => void,
    centerOnOffset?: boolean,
    header?: JSX.Element | string,
    style?: CSSProperties,
    className?: string,
    verbatimStyle?: CSSProperties,
    verbatimClassName?: string,
    separatorHeight: number
}

class InnerFeedbackComponent extends React.PureComponent<InnerFeedbackComponentProps> {
    static defaultProps: Partial<InnerFeedbackComponentProps> = {
        shouldHighlight: falseSupplier,
        centerOnOffset: false
    };

    highlighter = (offset, text) => {
        const {t} = this.props;
        return feedbackHighlight(offset, text, t);
    };

    filterOffsets(offsets) {
        const {shouldHighlight} = this.props;

        return (offsets || []).filter(offset => shouldHighlight(offset));
    }

    renderCenteredFeedback() {
        const {feedback} = this.props;

        // if there are no offsets in any verbatims, we display all verbatims
        if (!services.getFeedbackService().hasOffset(feedback)) {
            return this.renderVerbatims();
        }

        return join(services.getFeedbackService().getRelevantVerbatims(feedback)
                // we do not display verbatims that have no offsets
                .filter(verbatim => !_.isEmpty(verbatim.offsets))
                // keep only sentences that start on the first offset
                .map(verbatim => keepHighlightedSentences(verbatim?.content, verbatim.offsets, verbatim.sentences))
                // render
                .map(keep => <>
                    {keep.truncatedLeft ? '[...] ' : null}
                    <TextHighlight text={keep.text} offsets={this.filterOffsets(keep.offsets)}
                                   highlighter={this.highlighter}/>
                </>),
            <Icon icon='caret-right' style={{verticalAlign: 'middle'}}/>);
    }

    renderVerbatims() {
        const {feedback} = this.props;

        const channelKind = services.getFeedbackService().getChannelKind(feedback);
        const separator = channelKind?.conversational ?
            <Icon icon="caret-right" style={{verticalAlign: 'middle'}}/> : <br/>;
        const addTrailingSeparator = channelKind ? channelKind.conversational : false;

        return join(services.getFeedbackService().getRelevantVerbatims(feedback)
                .map(verbatim =>
                    <TextHighlight text={verbatim?.content}
                                   offsets={this.filterOffsets(verbatim.offsets)}
                                   highlighter={this.highlighter}/>),
            separator, addTrailingSeparator);
    }

    render() {
        const {
            onClick,
            centerOnOffset,
            header,
            style,
            className,
            verbatimStyle,
            verbatimClassName,
            separatorHeight
        } = this.props;

        return <div className={classnames('vui-layout--vertical', className)} style={style} onClick={onClick}>
            {header}
            <BlankSeparator size={separatorHeight} vertical={true}/>
            <div className={classnames(verbatimClassName)} style={verbatimStyle}>
                {
                    centerOnOffset ? this.renderCenteredFeedback() : this.renderVerbatims()
                }
            </div>
        </div>;
    }
}

export const InnerFeedback = withTranslation()(InnerFeedbackComponent);
