import { Tag } from '@blueprintjs/core';
import { Tooltip } from '@blueprintjs/core';
import _ from 'lodash';
import React, { CSSProperties, ReactElement } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FieldName } from '../application/model/field/Field';
import { services } from '../application/service/services';
import { BlankSeparator } from '../component/BlankSeparator';
import { Header } from '../component/Header';
import { SatisfactionIcon } from '../component/icon/SatisfactionIcon';
import { SmartIcon } from '../component/icon/SmartIcon';
import { LabelIcon } from '../component/LabelIcon';
import { SeparatedComponents } from '../component/SeparatedComponents';
import './FeedbackHeader.scss';
import { toArray } from '../utils/collectionUtils';
import { FieldDisplayKindType } from "../application/model/field/FieldDisplay";
import { FeedbackDTO } from "../application/model";
import { DateLabel } from "../component/dateLabel/DateLabel";
import { FieldValues } from "../application/model/field/FieldValues";

export class HeaderElement {
  id: string;
  component: ReactElement;
  anchor: 'left' | 'right';
}

export interface FeedbackHeaderProps {
  style?: CSSProperties,
  renderSat?: (feedback: FeedbackDTO) => JSX.Element,
  displayKind?: FieldDisplayKindType,
  fieldsHeaderGetter?: () => Array<FieldValues>,
  headerElements?: Array<HeaderElement>,
  renderSatisfactionIndicator?: boolean,
  renderChannel?: boolean,
  renderDate?: boolean
}

interface FeedbackHeaderComponentProps extends WithTranslation, FeedbackHeaderProps {
  feedback: FeedbackDTO
}

class FeedbackHeaderComponent extends React.PureComponent<FeedbackHeaderComponentProps> {
  static globalSatisfactionRenderer = (feedback) => {
    const globalSatisfaction = services.getFeedbackService().getGlobalSatisfaction(feedback);
    return <SatisfactionIcon satisfaction={globalSatisfaction} size={20} revertColor={true}/>;
  }

  renderSatisfactionIndicator() {
    const { feedback, renderSat } = this.props;
    const renderer = renderSat || FeedbackHeaderComponent.globalSatisfactionRenderer;

    return renderer(feedback);
  }

  renderChannel() {
    const { feedback, t } = this.props;

    const channelKind = services.getFeedbackService().getChannelKind(feedback);
    const channelKindIcon = _.isNil(channelKind) ? 'help' : channelKind.icon;
    const channelName = services.getFeedbackService().getChannelName(feedback);

    const channelComponent = _.isEmpty(channelName)
      ? <SmartIcon icon={channelKindIcon}/>
      : <LabelIcon icon={channelKindIcon} label={channelName}/>;

    if (_.isNil(channelKind)) {
      const channelKindAsString = services.getFeedbackService().getStandardMetadata(feedback, 'channelKind');

      const tooltipText = _.isEmpty(channelKindAsString)
        ? t('channelKind.empty', {
          channelKindFieldLabel: services.getFieldsService().getField(
            FieldName.standardMetadata('channelKind')).getLabel(t)
        })
        : t('channelKind.invalid', {
          channelKindFieldLabel: services.getFieldsService().getField(
            FieldName.standardMetadata('channelKind')).getLabel(t),
          channelKind: channelKindAsString
        });

      return <Tooltip content={tooltipText}>{channelComponent}</Tooltip>;
    }

    return channelComponent;

  }

  renderFields() {
    const { displayKind, fieldsHeaderGetter } = this.props
    let fieldValuesArray;

    if (fieldsHeaderGetter) {
      fieldValuesArray = fieldsHeaderGetter();
    } else {
      if (_.isNil(displayKind))
        return;
      fieldValuesArray = services.getFieldsService().getAllFieldValues(displayKind)
    }
    const content =fieldValuesArray.map(fieldValues => this.renderField(fieldValues)).filter(field => field)
    /*
        using gap with an ampty div generate a bug in print preview so export does not work.
        emtyFields is used only when there is no fields to display
        see. https://bugs.chromium.org/p/chromium/issues/detail?id=1161709
     */
    return <div className={content?.length > 0 ? 'fields' : 'emptyFields'}>
      {content}
    </div>;
  }

  renderField(fieldValues) {
    if (!fieldValues) return;
    const { t, feedback } = this.props;
    const rawValues = toArray(_.get(feedback, fieldValues.field.name));
    if (rawValues.length === 0) {
      return;
    }
    return rawValues.map(rawValue => {
      const fieldValue = fieldValues.find(rawValue);
      const valueLabel = fieldValue ? fieldValue.getLabel(t) : rawValue;
      const id = fieldValue ? fieldValue.getId() : `${fieldValues.field.name}/${rawValue}`;
      const fieldLabel = fieldValues.field.getLabel(t);
      const display = `${fieldLabel}: ${valueLabel}`;

      return <Tag className={'field'} key={id}>{display}</Tag>;
    });
  }

  formatValues(fieldValues, rawValues) {
    const { t } = this.props;
    return rawValues.map(rawValue => {
      const fieldValue = fieldValues.find(rawValue);
      if (fieldValue) {
        return fieldValue.getLabel(t);
      } else {
        return rawValue;
      }
    }).join(', ');
  }

  renderDate() {
    const { feedback } = this.props;
    return <LabelIcon className={'date'} icon='time' label={<DateLabel date={feedback.date}/>}/>;
  }

  getHeader() {
    const {
      style,
      displayKind,
      fieldsHeaderGetter,
      headerElements,
      renderSatisfactionIndicator,
      renderChannel,
      renderDate
    } = this.props;

    const renderItems = (items, anchor) => {
      items = items
        .filter(it => it.anchor === anchor)
        .map(it => React.cloneElement(it.component, { key: it.id }));

      return <SeparatedComponents separator={<BlankSeparator size={10}/>}>
        {items}
      </SeparatedComponents>;
    };


    let header: Array<HeaderElement> = [];

    if (renderSatisfactionIndicator) {
      header.push({
        id: 'satisfactionIndicator',
        component: this.renderSatisfactionIndicator(),
        anchor: 'left'
      });
    }

    if (renderChannel) {
      header.push({
        id: 'channel',
        component: this.renderChannel(),
        anchor: 'left'
      });
    }
    if (renderDate) {
      header.push({
        id: 'date',
        component: this.renderDate(),
        anchor: 'right'
      });
    }

    if (!_.isNil(displayKind) || !_.isNil(fieldsHeaderGetter)) {
      header.push(
        {
          id: 'fields',
          component: this.renderFields(),
          anchor: 'left'
        });
    }


    header = [...header, ...(headerElements || [])]

    return <Header className='feedbackHeader'
                   style={style}
                   title={renderItems(header, 'left')}
                   titleClassName={'headerTitle'}
                   rightComponent={renderItems(header, 'right')}
    />;
  }

  render() {
    return this.getHeader();
  }
}

export const FeedbackHeader = withTranslation()(FeedbackHeaderComponent);

FeedbackHeader.defaultProps = {
  renderDate: true,
  renderChannel: true,
  renderSatisfactionIndicator: true
}
export default FeedbackHeader;